import React, { useContext } from "react";
import Detail from "../../components/detailComponent/detailComponent";
import { GlobalContext } from "../../context/GlobalContext";

const DetailsSection = () => {
  const { textResources } = useContext(GlobalContext);
  const texts = textResources.home;
  const detailsInfo = [
    {
      id: "s&s-content-section",
      img: "img/shareable-content.png",
      title: texts.feature1Title,
      desc: texts.feature1Text,
      pos: "fade-right",
    },
    {
      id: "ai-generated-content-section",
      img: "img/ai-generated-content.png",
      title: texts.feature2Title,
      desc: texts.feature2Text,
      pos: "fade-left",
    },
    {
      id: "compatible-social-channel-section",
      img: "img/any-social-channel.png",
      title: texts.feature3Title,
      desc: texts.feature3Text,
      pos: "fade-right",
    },
    {
      id: "calendar-functionalities-section",
      img: "img/calendar.png",
      title: texts.feature4Title,
      desc: texts.feature4Text,
      pos: "fade-left",
    },
  ];

  return (
    <section id="details" className="details ">
      {detailsInfo.map((detail) => {
        return (
          <Detail
            key={detail.id}
            id={detail.id}
            img={detail.img}
            title={detail.title}
            desc={detail.desc}
            pos={detail.pos}
          />
        );
      })}
    </section>
  );
};

export default DetailsSection;
