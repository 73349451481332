import React from "react";
import * as languages from "../../utils/languages";
import { Link } from "react-router-dom";

const DropDownComponent = ({
  language,
  dropDown,
  toggleDropDown,
  inNavBar,
  isExternal,
}) => {
  return (
    <li
      onClick={toggleDropDown}
      className={!dropDown || inNavBar ? "drop-down" : "drop-down active"}
    >
      <Link to={!language || language == "en" ? "/" : "/lt"}>
        {!language || language === "en" ? languages.EN : languages.LT}
      </Link>

      <ul
        style={
          dropDown || inNavBar ? { display: "block" } : { display: "none" }
        }
      >
        {!isExternal ? (
          <li>
            <Link to={language ? "/" : `/lt`}>
              {language === "lt" ? languages.EN : languages.LT}
            </Link>
          </li>
        ) : null}
      </ul>
    </li>
  );
};

export default DropDownComponent;
