import React from "react";

const ModalComponent = (props) => {
  const modalStyle = {
    display: "block",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    height: "100%",
  };

  props.isOpen
    ? document.body.classList.add("modal-open")
    : document.body.classList.remove("modal-open");

  return (
    <div
      className="modal fade show"
      style={props.isOpen ? modalStyle : { display: "none" }}
      tabIndex="-1"
    >
      <div className="modal-dialog" role="document">
        {props.children}
      </div>
    </div>
  );
};

export default ModalComponent;
