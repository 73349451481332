import React, { useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";

const AboutSection = () => {
  const { textResources } = useContext(GlobalContext);
  const texts = textResources.home;

  return (
    <section id="about" className="about">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-lg-6 py-5" data-aos="fade-right">
            <img
              className="img-fluid"
              src="img/about.jpg"
              alt="Relaxed businessman with phone in car"
            />
          </div>
          <div
            className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5"
            data-aos="fade-left"
          >
            <h3>{texts.aboutTitle}</h3>
            <p>{texts.aboutText}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
