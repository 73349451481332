import React, { createContext, useReducer } from "react";
import AppReducer from "./AppReducer";
import { getTextResource } from "../clients/textResourceClient";
import * as actions from "./actions";

const initialState = {
  textResources: [],
  isLoading: true,
  currentLang: null,
  ccAccepted: false,
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  const setLanguage = async (language, file) => {
    dispatch({
      type: actions.SET_LANGUAGE,
      payload: language,
    });
    await getTextResources(language, file);
  };

  const getTextResources = async (language, file) => {
    if (state.currentLang == language && state.textResources[file]) {
      return;
    }
    await getTextResource(language, file).then((resources) => {
      dispatch({
        type: actions.SET_TXT_RESOURCES,
        payload: resources,
      });
    });
  };

  const setLoader = (boolean) => {
    dispatch({
      type: actions.SET_LOADER,
      payload: boolean,
    });
  };
  const setCcAccepted = (isAccepted) => {
    dispatch({
      type: actions.SET_CC_ACCEPTED,
      payload: isAccepted,
    });
  };

  return (
    <GlobalContext.Provider
      value={{
        textResources: state.textResources,
        isLoading: state.isLoading,
        ccAccepted: state.ccAccepted,
        setLanguage: setLanguage,
        setLoader: setLoader,
        setCcAccepted: setCcAccepted,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
