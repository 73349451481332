import React, { useContext } from "react";
import FaqItem from "../../utils/faqItem/faqItem";
import { GlobalContext } from "../../context/GlobalContext";
const FaqSection = () => {
  const { textResources } = useContext(GlobalContext);
  const texts = textResources.home;
  const items = [
    {
      delay: "",
      id: "faq-list-1",
      title: texts.faq1Title,
      desc: texts.faq1Text,
    },
    {
      delay: "100",
      id: "faq-list-2",
      title: texts.faq2Title,
      desc: texts.faq2Text,
    },
    {
      delay: "200",
      id: "faq-list-3",
      title: texts.faq3Title,
      desc: texts.faq3Text,
    },
    {
      delay: "300",
      id: "faq-list-4",
      title: texts.faq4Title,
      desc: texts.faq4Text,
    },
    {
      delay: "300",
      id: "faq-list-5",
      title: texts.faq5Title,
      desc: texts.faq5Text,
    },
  ];

  return (
    <section id="faq" className="faq section-bg">
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>{texts.faq}</h2>
          <p>{texts.faqTitle}</p>
        </div>
        <div className="faq-list">
          <ul>
            {items.map((item) => {
              return (
                <FaqItem
                  key={item.id}
                  id={item.id}
                  delay={item.delay}
                  title={item.title}
                  desc={item.desc}
                />
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default FaqSection;
