import React, { useContext, useEffect } from "react";
import Spinner from "../../components/spinnerComponent/spinnerComponent";
import NavBar from "../../utils/navBar/navBar";
import Footer from "../../utils/footer/footer";
import { GlobalContext } from "../../context/GlobalContext";
import { useParams } from "react-router-dom";
import "./privacyPolicy.css";

const PrivacyPage = () => {
  const { textResources, isLoading, setLanguage, setLoader } =
    useContext(GlobalContext);

  const { language } = useParams();
  const texts = textResources.PrivacyPage;

  useEffect(() => {
    setLoader(true);
    Promise.all([
      setLanguage(language),
      setLanguage(language, "PrivacyPage"),
    ]).then(() => setLoader(false));
  }, []);

  if (!isLoading) {
    return (
      <div style={{ overflowY: "hidden" }}>
        <NavBar isExternal={true} />
        <div
          className="privacy-policy"
          dangerouslySetInnerHTML={{ __html: texts?.privacyPolicyPage }}
        ></div>
        <Footer />
      </div>
    );
  } else {
    return <Spinner />;
  }
};
export default PrivacyPage;
