import React, { useContext, useState } from "react";
import "./contactformUtil.css";
import { GlobalContext } from "../../context/GlobalContext";
import { sendEmail } from "../../clients/sendEmailClient";
import Input from "../../components/inputComponent/inputComponent";
import TextArea from "../../components/textareaComponent/textareaComponent";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { Cookies } from "react-cookie-consent";

const ContactFormUtil = () => {
  const [token, setToken] = useState();

  const { textResources } = useContext(GlobalContext);
  const texts = textResources.form;

  const [isEmailSend, setEmailSend] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [reCaptchaStatus, setRecaptchaStatus] = useState(true);

  // Input Fields States
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [msg, setMsg] = useState("");

  const [errMessages, setErrMessages] = useState({
    nameErr: "",
    emailErr: "",
    subjectErr: "",
    msgErr: "",
  });

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const validate = () => {
    let nameErr = "";
    let subjectErr = "";
    let emailErr = "";
    let msgErr = "";
    if (!name) {
      nameErr = texts.nameErr;
    } else {
      setIsValid(true);
    }
    if (!subject) {
      subjectErr = texts.subjectErr;
    } else {
      setIsValid(true);
    }
    if (!validateEmail(email)) {
      emailErr = texts.emailErr;
    } else {
      setIsValid(true);
    }
    if (!msg) {
      msgErr = texts.msgErr;
    } else {
      setIsValid(true);
    }

    if (nameErr || subjectErr || emailErr || msgErr) {
      setErrMessages({ nameErr, subjectErr, emailErr, msgErr });
      return false;
    }
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const isValidated = validate();

    if (isValidated) {
      const emailData = { name, email, subject, msg, token };

      sendEmail(emailData)
        .catch((error) => {
          console.warn(error);
          setRecaptchaStatus(false);
        })
        .then(setEmailSend(true));

      setErrMessages({
        nameErr: "",
        emailErr: "",
        subjectErr: "",
        msgErr: "",
      });

      setIsValid(false);

      setName("");
      setEmail("");
      setSubject("");
      setMsg("");
    }
  };

  const info = [
    {
      name: "address",
      icon: "icofont-google-map",
      title: texts.location,
      desc: "Kaunas, Lithuania",
    },
    {
      name: "email",
      icon: "icofont-envelope",
      title: texts.mail,
      desc: " info@anymoment.app",
    },
  ];

  return (
    <div className="row">
      <div className="col-lg-4" data-aos="fade-right" data-aos-delay="100">
        <div className="info">
          {info.map((info) => {
            return (
              <div key={info.name} className={info.name}>
                <i className={info.icon}></i>
                <h4>{info.title}</h4>
                <p>{info.desc}</p>
              </div>
            );
          })}
        </div>
      </div>

      <div
        className="col-lg-8 mt-5 mt-lg-0"
        data-aos="fade-left"
        data-aos-delay="200"
      >
        <form className="php-email-form" onSubmit={handleSubmit}>
          <div className="form-row">
            <Input
              className="col-md-6 form-group"
              onChange={(event) => setName(event.target.value)}
              value={name}
              name="name"
              placeholder={texts.yourName}
              errMessage={errMessages.nameErr}
              isValid={isValid}
            />

            <Input
              className="col-md-6 form-group"
              onChange={(event) => setEmail(event.target.value)}
              value={email}
              name="email"
              placeholder={texts.yourEmail}
              errMessage={errMessages.emailErr}
              isValid={isValid}
            />
          </div>
          <Input
            className="form-group"
            onChange={(event) => setSubject(event.target.value)}
            value={subject}
            name="subject"
            placeholder={texts.subject}
            errMessage={errMessages.subjectErr}
            isValid={isValid}
          />
          <TextArea
            onChange={(event) => setMsg(event.target.value)}
            name="msg"
            value={msg}
            placeholder={texts.msg}
            errMessage={errMessages.msgErr}
            isValid={isValid}
          />
          <div className="mb-3">
            {!reCaptchaStatus ? (
              <div
                style={
                  !reCaptchaStatus && isEmailSend
                    ? { display: "block" }
                    : { display: "none" }
                }
                className="alert alert-danger"
              >
                {texts.failedRecaptcha}
              </div>
            ) : (
              <div
                style={
                  reCaptchaStatus && isEmailSend
                    ? { display: "block" }
                    : { display: "none" }
                }
                className="alert alert-success"
              >
                {texts.messageSendText}
              </div>
            )}
          </div>
          {Cookies.get().acceptionCookie ? (
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            >
              <GoogleReCaptcha onVerify={setToken} />
              <div className="text-center">
                {!isEmailSend && <button type="submit">{texts.sendMsg}</button>}
              </div>
            </GoogleReCaptchaProvider>
          ) : (
            <div className="alert alert-warning">
              {texts.pleaseAcceptCookies}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default ContactFormUtil;
