import React, { useEffect, useState, useContext } from "react";
import "./navBar.css";
import NavModal from "../navModal/navModal";
import { Link, useParams } from "react-router-dom";
import * as languages from "../languages";
import { GlobalContext } from "../../context/GlobalContext";
import logoLight from "../../img/demo-logo-light.png";
import DropDown from "../../components/dropDownComponent/dropDownComponent";

const NavBar = (props) => {
  const { textResources } = useContext(GlobalContext);
  const texts = textResources.home;
  const backgroundStates = {
    transperant:
      "fixed-top d-flex align-items-center header header-transparent",
    solid: "header-scrolled",
  };

  const [navClasses, setNavClasses] = useState(backgroundStates.transperant);
  const { language } = useParams();

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 10;
      if (show) {
        setNavClasses(
          backgroundStates.transperant.concat(" ", backgroundStates.solid)
        );
      } else {
        setNavClasses(backgroundStates.transperant);
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const listItemInfo = [
    { href: "#hero", title: texts.home },
    { href: "#about", title: texts.about },
    { href: "#features", title: texts.features },
    { href: "#faq", title: texts.faq },
    { href: "#contact", title: texts.contact },
  ];

  return (
    <header
      id="header"
      className={
        !props.isExternal
          ? navClasses
          : "header-scrolled d-flex align-items-center header"
      }
    >
      <NavModal isExternal={props.isExternal} />

      <div className="container d-flex align-items-center">
        <div className="logo mr-auto">
          <a href={!language ? "/" : `/${language}`}>
            <img src={logoLight} alt="logo" className="img-fluid" />
          </a>
        </div>
        <nav className="nav-menu d-none d-lg-block">
          <ul>
            {listItemInfo.map((item) => {
              return (
                <li
                  key={item.title}
                  className={props.activePage === item.href ? "active" : ""}
                >
                  <a
                    onClick={() => props.onActivePageChange(item.href)}
                    href={
                      props.isExternal ? `/${language}${item.href}` : item.href
                    }
                  >
                    {item.title}
                  </a>
                </li>
              );
            })}
            <DropDown
              isExternal={props.isExternal}
              inNavBar={true}
              language={language}
            />
          </ul>
        </nav>
      </div>
    </header>
  );
};
export default NavBar;
