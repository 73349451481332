import React from "react";
import "./detailComponent.css";

const DetailComponent = (props) => {
  return (
    <div className="container-fluid py-3">
      <div id={props.id} className="container">
        <div className="row content">
          <div
            className={
              props.pos === "fade-left"
                ? "col-lg-3 offset-lg-2 col-md-6 order-1 order-md-2"
                : "col-lg-3 offset-lg-2 col-md-6"
            }
            data-aos={props.pos}
          >
            <img
              src={props.img}
              className="img-fluid"
              alt="AnyMoment application notification screen"
            ></img>
          </div>
          <div
            className={
              props.pos === "fade-left"
                ? "col-lg-5 col-md-6 d-flex flex-column align-items-stretch justify-content-center py-5 order-2 order-md-1 text-right"
                : "col-lg-5 col-md-6 offset-lg-2 d-flex flex-column align-items-stretch justify-content-center py-5"
            }
            data-aos="fade-up"
          >
            <h3>{props.title}</h3>
            <p className="font-italic text-justify">{props.desc}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailComponent;
