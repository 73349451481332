import React, { useContext, useEffect } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { GlobalContext } from "../../context/GlobalContext";
import "./cookieWrapperUtil.css";
import Spinner from "../../components/spinnerComponent/spinnerComponent";

const CookieWrapper = ({ onAccept }) => {
  const { textResources, isLoading, setCcAccepted } = useContext(GlobalContext);

  useEffect(() => {
    if (Cookies.get().acceptionCookie) {
      setCcAccepted(true);
      onAccept();
    }
  }, []);

  if (!isLoading) {
    return (
      <CookieConsent
        location="bottom"
        buttonText={textResources.cookieConsent.gotIt}
        cookieName="acceptionCookie"
        sameSite="Lax"
        cookieSecurity={false}
        expires={10}
        onAccept={() => {
          setCcAccepted(true);
          onAccept();
        }}
      >
        {textResources.cookieConsent.cookieText}
        <span>
          <a href="en/privacy"> {textResources.cookieConsent.learnMore}</a>
        </span>
      </CookieConsent>
    );
  } else {
    return <Spinner />;
  }
};

export default CookieWrapper;
