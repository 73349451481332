import React from "react";
import "./collapseComponent.css";
const CollapseComponent = (props) => {
  return (
    <div
      className="custom-collapse"
      style={props.isOpen ? { maxHeight: "1000px" } : { maxHeight: "0px" }}
    >
      {props.children}
    </div>
  );
};

export default CollapseComponent;
