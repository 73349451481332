import React, { useState, useContext, useEffect } from "react";
import NavBar from "../../utils/navBar/navBar";
import Hero from "./heroSection";
import About from "./aboutSection";
import Features from "./featuresSection";
import Details from "./detailsSection";
import Faq from "./faqSection";
import Contact from "./contactSection";
import Footer from "../../utils/footer/footer";
import BackButton from "../../components/backButtonComponent/backButtonComponent";
import "./style.css";
import Spinner from "../../components/spinnerComponent/spinnerComponent";
import { GlobalContext } from "../../context/GlobalContext";
import { useParams, useLocation } from "react-router-dom";

const HomePage = () => {
  const [activePage, setActivePage] = useState("hero");
  const { isLoading, setLanguage, setLoader } = useContext(GlobalContext);

  const { language } = useParams();
  const location = useLocation();

  useEffect(() => {
    setLoader(true);
    setLanguage(language).then(() => setLoader(false));
  }, [language]);

  useEffect(() => {
    setTimeout(() => {
      if (!isLoading) {
        if (location.hash) {
          window.location.href = location.hash;
        }
      }
    }, 300);
  }, [isLoading]);

  if (!isLoading) {
    return (
      <div>
        <NavBar onActivePageChange={setActivePage} activePage={activePage} />
        <Hero />
        <div id="main">
          <About />
          <Features />
          <Details />
          <Faq />
          <Contact />
          <Footer />
          <BackButton
            onActivePageChange={setActivePage}
            activePage={activePage}
          />
        </div>
      </div>
    );
  } else {
    return <Spinner />;
  }
};

export default HomePage;
