import React, { useState, useContext } from "react";
import Modal from "../../components/modalComponent/modalComponent";
import "./navModal.css";
import ModalBody from "../../components/modalComponent/modalBody";
import { useParams } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import DropDown from "../../components/dropDownComponent/dropDownComponent";

const NavModal = (props) => {
  const { textResources } = useContext(GlobalContext);
  const texts = textResources.home;

  const [isModalVisible, setModalVisible] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const { language } = useParams();

  const toggle = () => {
    isModalVisible
      ? setTimeout(() => setModalVisible(!isModalVisible), 500)
      : setModalVisible(!isModalVisible);
  };

  const toggleDropDown = () => setDropDown(!dropDown);

  const listItemInfo = [
    { href: "#hero", title: texts.home },
    { href: "#about", title: texts.about },
    { href: "#features", title: texts.features },
    { href: "#faq", title: texts.faq },
    { href: "#contact", title: texts.contact },
  ];

  return (
    <>
      <button
        onClick={toggle}
        type="button"
        className={
          props.isExternal
            ? " position-absolute mobile-nav-toggle d-lg-none"
            : "mobile-nav-toggle d-lg-none"
        }
        style={isModalVisible ? { display: "none" } : { display: "block" }}
      >
        <i className="icofont-navigation-menu"></i>
      </button>

      <Modal isOpen={isModalVisible} toggle={toggle}>
        <ModalBody toggle={toggle}>
          <nav className="mobilenav">
            <ul>
              {listItemInfo.map((item) => {
                return (
                  <li key={item.title} onClick={toggle}>
                    <a
                      href={
                        props.isExternal
                          ? `/${language}${item.href}`
                          : item.href
                      }
                    >
                      {item.title}
                    </a>
                  </li>
                );
              })}
              <DropDown
                language={language}
                dropDown={dropDown}
                toggleDropDown={toggleDropDown}
              />
            </ul>
          </nav>
        </ModalBody>
      </Modal>
    </>
  );
};
export default NavModal;
