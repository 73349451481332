import React, { useEffect, useState } from "react";
import "./backButtonComponent.css";

const BackButtonComponent = (props) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const show = window.scrollY > 10;
    setScrolled(show);
  };

  return (
    <a
      href="#hero"
      className="back-to-top"
      style={!scrolled ? { display: "none" } : { display: "inline" }}
      onClick={() => props.onActivePageChange("hero")}
    >
      <i className="icofont-simple-up"></i>
    </a>
  );
};

export default BackButtonComponent;
