import React, { useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import ContactForm from "../../utils/contactFormUtil/contactFormUtil";

const ContactSection = () => {
  const { textResources } = useContext(GlobalContext);
  const texts = textResources.home;
  return (
    <section id="contact" className="contact">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>{texts.contact}</h2>
          <p>{texts.contactTitle}</p>
        </div>
        {/* <p>{texts.contactText}</p> */}
        <ContactForm />
      </div>
    </section>
  );
};

export default ContactSection;
