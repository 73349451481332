import React from "react";

const InputComponent = ({
  className,
  onChange,
  name,
  placeholder,
  errMessage,
  value,
  isValid,
}) => {
  return (
    <div className={className}>
      <input
        onChange={onChange}
        value={value}
        type="text"
        name={name}
        className={`form-control ${
          errMessage ? "is-invalid" : isValid && "is-valid"
        }`}
        placeholder={placeholder}
      />
      <p className="errorMsg">{errMessage}</p>
    </div>
  );
};

export default InputComponent;
