import React, { useState } from "react";
import Collapse from "../../components/collapseComponent/collapseComponent";
import "./faqItem.css";

const FaqItem = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <li data-aos="fade-up" data-aos-delay={props.delay}>
      <i className="bx bx-help-circle icon-help"></i>
      <a
        onClick={toggle}
        data-toggle="collapse"
        className={isOpen ? "" : "collapsed"}
      >
        {props.title}
        <i className="bx bx-chevron-down icon-show"></i>
        <i className="bx bx-chevron-up icon-close"></i>
      </a>
      <Collapse isOpen={isOpen} id={props.id}>
        <p>{props.desc}</p>
      </Collapse>
    </li>
  );
};

export default FaqItem;
