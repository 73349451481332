import React, { useContext } from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";

const Footer = () => {
  const { textResources } = useContext(GlobalContext);
  const texts = textResources.home;

  const footerLinks = [
    { link: "/en/terms", title: texts.termsOfService },
    { link: "/en/privacy", title: texts.privacyPolicy },
  ];

  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-3 footer-links text-center text-md-left">
              <h4>{texts.usefullLinks}</h4>
              <ul>
                {footerLinks.map((link) => {
                  return (
                    <li key={link.title}>
                      <i className="bx bx-chevron-right"></i>
                      <Link to={link.link}>{link.title}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="col-lg-4 offset-lg-2 col-md-6">
              <div className="footer-info">
                <h3>AnyMoment</h3>
                <p>
                  <i className="icofont-envelope"></i>
                  <strong>{texts.email}: </strong>
                  <a href="mailto:info@anymoment.app">info@anymoment.app</a>
                  <br></br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          &copy; Copyright
          <strong>
            <span>
              <a href="https://www.confistech.com"> ConfiStecH</a>
            </span>
          </strong>
          . All Rights Reserved
        </div>
      </div>
    </footer>
  );
};

export default Footer;
