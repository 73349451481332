import React from "react";

const TextareaComponent = ({
  onChange,
  name,
  placeholder,
  errMessage,
  value,
  isValid,
  rows,
}) => {
  return (
    <div className="form-group">
      <textarea
        onChange={onChange}
        className={`form-control ${
          errMessage ? "is-invalid" : isValid ? "is-valid" : null
        }`}
        name={name}
        rows="5"
        value={value}
        rows={rows}
        placeholder={placeholder}
      ></textarea>
      <p className="errorMsg">{errMessage}</p>
    </div>
  );
};

export default TextareaComponent;
