import React from "react";

function ModalBody(props) {
  return (
    <div className="modal-content">
      <div className="modal-body">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={props.toggle}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        {props.children}
      </div>
    </div>
  );
}
export default ModalBody;
