import React, { useContext } from "react";
import Feature from "../../components/featureComponent/featureComponent";
import { GlobalContext } from "../../context/GlobalContext";
const FeaturesSection = () => {
  const { textResources } = useContext(GlobalContext);
  const texts = textResources.home;

  const featuresInfo = [
    {
      link: "#s&s-content-section",
      iconName: "ri-share-line",
      desc: texts.feature1Title,
      color: "#ffbb2c",
      width: "col-xl-3 col-md-6",
    },
    {
      link: "#ai-generated-content-section",
      iconName: "ri-settings-5-line",
      desc: texts.feature2Title,
      color: "#5578ff",
      width: "col-xl-3 col-md-6",
    },
    {
      link: "#compatible-social-channel-section",
      iconName: "ri-calendar-todo-line",
      desc: texts.feature3Title,
      color: "#29cc61",
      width: "col-xl-3 col-md-6",
    },
    {
      link: "#calendar-functionalities-section",
      iconName: "ri-calendar-todo-line",
      desc: texts.feature4Title,
      color: "#ff5828",
      width: "col-xl-3 col-md-6",
    },
  ];

  return (
    <section id="features" className="features">
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>{texts.features}</h2>
          <p>{texts.featuresTitle}</p>
        </div>
        <div className="row" data-aos="fade-left">
          {featuresInfo.map((feature) => {
            return (
              <Feature
                key={feature.link}
                width={feature.width}
                link={feature.link}
                iconName={feature.iconName}
                desc={feature.desc}
                color={feature.color}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
