import React, { useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";
const HeroSection = () => {
  const { textResources } = useContext(GlobalContext);
  const texts = textResources.home;
  return (
    <section id="hero">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 pt-5">
            <div data-aos="zoom-out">
              <h1>
                <span>
                  {texts.rememberOnce} <br></br> {texts.impactForever}
                </span>
              </h1>
              <h2>{texts.heroText}</h2>

              <div className="store-wrapper">
                <a href="https://play.google.com/store/apps/details?id=confistech.mvp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                  <img
                    alt="Get it on Google Play"
                    width="38%"
                    src="img/google-badge.png"
                    className="d-none d-lg-inline mr-2 "
                  />
                  <img
                    alt="Get it on Google Play"
                    width="45%"
                    src="img/google-badge.png"
                    className="d-lg-none d-block mb-2"
                  />
                </a>
                {/* THIS PART IS COMMENTED AS CURRENTLY ANYMOMENT APP IS NOT AVAILABLE ON APP STORE */}
                {/* <a href="https://apps.apple.com/us/app/anymoment-scheduler/id1550958842">
                  <img
                    alt="Download on the App Store"
                    width="35%"
                    src="img/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
                    className="d-none d-lg-inline"
                  />
                  <img
                    alt="Download on the App Store"
                    width="45%"
                    src="img/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
                    className="d-lg-none d-block"
                  />
                </a> */}
              </div>
            </div>
          </div>

          <div
            className="col-lg-5 hero-img"
            data-aos="zoom-out"
            data-aos-delay="300"
          >
            <img
              src="img/hero-img.png"
              className="img-fluid animated"
              alt="Woman relaxing with a phone in nature."
            ></img>
          </div>
        </div>
      </div>

      <svg
        className="hero-waves"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28 "
        preserveAspectRatio="none"
      >
        <defs>
          <path
            id="wave-path"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          />
        </defs>
        <g className="wave1">
          <use
            xlinkHref="#wave-path"
            x="50"
            y="3"
            fill="rgba(255,255,255, .1)"
          />
        </g>
        <g className="wave2">
          <use
            xlinkHref="#wave-path"
            x="50"
            y="0"
            fill="rgba(255,255,255, .2)"
          />
        </g>
        <g className="wave3">
          <use xlinkHref="#wave-path" x="50" y="9" fill="#fff" />
        </g>
      </svg>
    </section>
  );
};

export default HeroSection;
