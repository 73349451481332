import React from "react";
import "./style.css";

const Feature = (props) => {
  return (
    <div className={props.width}>
      <div className="icon-box" data-aos="zoom-in" data-aos-delay="50">
        <i className={props.iconName} style={{ color: props.color }}></i>
        <h3>
          <a href={props.link}>{props.desc}</a>
        </h3>
      </div>
    </div>
  );
};

export default Feature;
