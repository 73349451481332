import { useEffect } from "react";
import HomePage from "./pages/home/homePage";
import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { GlobalProvider } from "./context/GlobalContext";
import { BrowserRouter as Router, Route } from "react-router-dom";
import PrivacyPage from "./pages/privacy/privacyPage";
import TermsPage from "./pages/terms/termsPage";
import CookieWrapper from "./utils/cookieWrapperUtil/cookieWrapperUtil";
import ReactGa from "react-ga";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);

  const onAcceptCookies = () => {
    ReactGa.initialize("UA-159141703-2", {});
    ReactGa.pageview(window.location.pathname + window.location.search);
  };

  return (
    <div className="App">
      <Router>
        <GlobalProvider>
          <div>
            <Route exact path={["/:language", "/"]} component={HomePage} />
            <Route
              exact
              path={["/:language/privacy"]}
              component={PrivacyPage}
            />
            <Route exact path={["/:language/terms"]} component={TermsPage} />
            <CookieWrapper onAccept={onAcceptCookies} />
          </div>
        </GlobalProvider>
      </Router>
    </div>
  );
};
export default App;
